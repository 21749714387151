<template>
  <div class="mainList">
    <div class="mainList_operation clearfloat">
      <div class="mainList_operation_inline_block">
        <div class="fl colorBlue">{{ data.dictName }}字典详情</div>

        <div class="fr" v-if="data.dictType">
          <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
        </div>
      </div>
    </div>
    <div class="mainList_content">
      <div class="mainList_content_sub" v-if="data.dictType">
        <el-table
          ref="multipleTable"
          row-key="id"
          border
          :data="tableData"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          tooltip-effect="dark"
          height="string"
          :default-expand-all="false"
          v-loading="loading"
        >
          <el-table-column align="left" prop="dictName" label="名称"></el-table-column>
          <el-table-column prop="dictVal" align="center" label="值"></el-table-column>
          <el-table-column label="操作" align="center" fixed="right" width="200">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                class="text_Edit_Bgc"
                @click.stop="edit(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" class="text_Remove_Bgc" size="small" @click="del(scope)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-result v-else icon="info" title="" subTitle="点击字典查看详情"></el-result>
    </div>

    <SubEdit
      :isShow.sync="showEditDialog"
      :options="options"
      :rulesData="rulesData"
      :treeselectOptions="treeselectOptions"
    ></SubEdit>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'

export default {
  components: {
    SubEdit: () => import('./SubEdit.vue'),
    Treeselect: () => import('@riophae/vue-treeselect'),
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      tableData: [],
      treeselectOptions: [],
      loading: false,
      showEditDialog: false,
      rulesData: [],
      options: {},
    }
  },
  watch: {
    data: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (newVal.dictType) {
          this.getData()
        }
      },
    },
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.getData()
    },
    add() {
      this.options = {
        parentId: 0,
        dictVal: '',
        dictType: this.data.dictType,
      }
      this.showEditDialog = true
    },
    edit(row) {
      this.options = row.deepClone()
      this.showEditDialog = true
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.editDialogLoading = true
          this.$api.dict
            .save(Object.assign(this.form))
            .then(res => {
              this.editDialogLoading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.getData()
            })
            .catch(err => {
              this.editDialogLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
    getData() {
      this.loading = true
      this.$api.dict
        .listSysDictData(this.data.dictType)
        .then(res => {
          this.loading = false
          this.rulesData = res.data
          this.tableData = initTreeData(res.data)
          this.treeselectOptions = [
            {
              id: 0,
              name: '顶级字典',
              children: this.tableData,
            },
          ]
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    del(scope) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.dict
            .del(scope.row.id)
            .then(res => {
              this.$message.success('删除成功！')
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.dictMain {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 45px);
  padding: 20px;
  .mainList {
    background: #fff;
    .mainList_operation {
      padding: 0;
    }
    .mainList_content_sub {
      padding: 0;
    }
    &:first-child {
      width: 45%;
    }
    &:last-child {
      width: calc(55% - 20px);
    }
  }
}
</style>
